@font-face {
    font-family: "uf";
    src: url('./iconfont.eot?t=1541574327301'); /* IE9*/
    src: url('./iconfont.eot?t=1541574327301#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./iconfont.woff?t=1541574327301') format('woff'),
    url('./iconfont.ttf?t=1541574327301') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/ url('./iconfont.svg?t=1541574327301#uf') format('svg'); /* iOS 4.1- */
}

.uf {
    font-family: "uf";
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.uf-wechat:before {
    content: "\3488";
}

.uf-add-c-o:before {
    content: "\e601";
}

.uf-search:before {
    content: "\e603";
}

.uf-histogram-arrow-up:before {
    content: "\e633";
}

.uf-close-bold:before {
    content: "\e602";
}

.uf-umbrella:before {
    content: "\e63b";
}

.uf-qq:before {
    content: "\e694";
}

.uf-4square-3:before {
    content: "\e63d";
}

.uf-send:before {
    content: "\e63f";
}

.uf-map:before {
    content: "\e66c";
}

.uf-9square-2:before {
    content: "\e63e";
}

.uf-navmenu:before {
    content: "\e614";
}

.uf-pc-2:before {
    content: "\e644";
}

.uf-search-light-2:before {
    content: "\e627";
}

.uf-check-s-2:before {
    content: "\e66e";
}

.uf-pencil:before {
    content: "\e69b";
}

.uf-repeat:before {
    content: "\e634";
}

.uf-security-2:before {
    content: "\e6a5";
}

.uf-lexi:before {
    content: "\e643";
}

.uf-pencil-s:before {
    content: "\e77d";
}

.uf-del:before {
    content: "\e654";
}

.uf-bi-o:before {
    content: "\e641";
}

.uf-pencil-c:before {
    content: "\e6e6";
}

.uf-qrcode:before {
    content: "\e661";
}

.uf-rmb-c-o:before {
    content: "\e645";
}

.uf-search-c-o:before {
    content: "\e659";
}

.uf-bell:before {
    content: "\e60c";
}

.uf-pass-3:before {
    content: "\e649";
}

.uf-treearrow-down:before {
    content: "\e608";
}

.uf-training:before {
    content: "\e617";
}

.uf-group-2:before {
    content: "\e64c";
}

.uf-zoom-in:before {
    content: "\e906";
}

.uf-security-o:before {
    content: "\e7ac";
}

.uf-baojia-c:before {
    content: "\e64d";
}

.uf-rulerpen:before {
    content: "\e623";
}

.uf-erpsearch:before {
    content: "\e64e";
}

.uf-group-o:before {
    content: "\e6a8";
}

.uf-cloud-o-updown:before {
    content: "\e725";
}

.uf-close-c-o:before {
    content: "\e625";
}

.uf-add-s:before {
    content: "\e620";
}

.uf-pc:before {
    content: "\e6e8";
}

.uf-rain:before {
    content: "\e771";
}

.uf-nodata:before {
    content: "\e64f";
}

.uf-close-c:before {
    content: "\e66b";
}

.uf-bohui-s-o:before {
    content: "\e65f";
}

.uf-cloud:before {
    content: "\e772";
}

.uf-bag-s:before {
    content: "\e650";
}

.uf-table-2:before {
    content: "\e95f";
}

.uf-anglearrowpointingtoright:before {
    content: "\e61e";
}

.uf-exc-c-o:before {
    content: "\e615";
}

.uf-group:before {
    content: "\e651";
}

.uf-personin-o:before {
    content: "\e8d3";
}

.uf-calendar:before {
    content: "\e646";
}

.uf-add-s-o:before {
    content: "\e604";
}

.uf-sync-c-o:before {
    content: "\e655";
}

.uf-grid:before {
    content: "\e657";
}

.uf-anglepointingtoleft:before {
    content: "\e624";
}

.uf-activate-3:before {
    content: "\e65b";
}

.uf-caven:before {
    content: "\e663";
}

.uf-back:before {
    content: "\e6e3";
}

.uf-pass-2:before {
    content: "\e65d";
}

.uf-reduce-s-o:before {
    content: "\e719";
}

.uf-area:before {
    content: "\e6ea";
}

.uf-flag:before {
    content: "\e65e";
}

.uf-box-o-2:before {
    content: "\e610";
}

.uf-arrow-s-o-down:before {
    content: "\e660";
}

.uf-arrow-s-o-up:before {
    content: "\e662";
}

.uf-building:before {
    content: "\e6ee";
}

.uf-tapp:before {
    content: "\e773";
}

.uf-treefolder:before {
    content: "\e628";
}

.uf-advice:before {
    content: "\e6cf";
}

.uf-2collayout:before {
    content: "\e618";
}

.uf-check-s:before {
    content: "\e672";
}

.uf-sign:before {
    content: "\e67a";
}

.uf-listsearch:before {
    content: "\e67c";
}

.uf-gridcaretarrowup:before {
    content: "\e636";
}

.uf-eye-c-o:before {
    content: "\e67e";
}

.uf-check-c-o:before {
    content: "\e6ef";
}

.uf-seal:before {
    content: "\e67f";
}

.uf-erpbox:before {
    content: "\e6ae";
}

.uf-rulerpen-o:before {
    content: "\e62e";
}

.uf-role:before {
    content: "\e7c2";
}

.uf-exc-c-2:before {
    content: "\e675";
}

.uf-pad:before {
    content: "\e6bd";
}

.uf-treefolder-closed:before {
    content: "\e688";
}

.uf-reduce-c-o:before {
    content: "\e635";
}

.uf-pass-s-o:before {
    content: "\e682";
}

.uf-setting:before {
    content: "\e683";
}

.uf-close-s:before {
    content: "\e76a";
}

.uf-map-o:before {
    content: "\e665";
}

.uf-move:before {
    content: "\e68b";
}

.uf-2arrow-down:before {
    content: "\e606";
}

.uf-2arrow-right:before {
    content: "\e60b";
}

.uf-arrow-c-o-left:before {
    content: "\e684";
}

.uf-plus:before {
    content: "\e763";
}

.uf-arrow-c-o-right:before {
    content: "\e685";
}

.uf-arrow-c-o-down:before {
    content: "\e687";
}

.uf-list-s-o:before {
    content: "\e746";
}

.uf-cloud-o-down:before {
    content: "\e673";
}

.uf-nodata-2:before {
    content: "\e68c";
}

.uf-file-s:before {
    content: "\e642";
}

.uf-2arrow-up:before {
    content: "\e607";
}

.uf-notification:before {
    content: "\e9cc";
}

.uf-piechart:before {
    content: "\e6f0";
}

.uf-cloud-o-up:before {
    content: "\e6ac";
}

.uf-close:before {
    content: "\e76b";
}

.uf-correct:before {
    content: "\e677";
}

.uf-histogram-s-o-2:before {
    content: "\e6fa";
}

.uf-4square-2:before {
    content: "\e691";
}

.uf-sunny:before {
    content: "\e774";
}

.uf-link:before {
    content: "\e6fe";
}

.uf-eye:before {
    content: "\e692";
}

.uf-eye-o:before {
    content: "\e69c";
}

.uf-qian:before {
    content: "\e69e";
}

.uf-widgetab:before {
    content: "\e6f2";
}

.uf-rmb-s:before {
    content: "\e6a0";
}

.uf-link-off:before {
    content: "\e6ff";
}

.uf-shang-s:before {
    content: "\e6a1";
}

.uf-xia-s:before {
    content: "\e6a6";
}

.uf-box-2:before {
    content: "\e616";
}

.uf-pass-o:before {
    content: "\e6a7";
}

.uf-arrow-down:before {
    content: "\e609";
}

.uf-arrow-right:before {
    content: "\e611";
}

.uf-arrow-left:before {
    content: "\e612";
}

.uf-box:before {
    content: "\e613";
}

.uf-triangle-right:before {
    content: "\e61c";
}

.uf-histogram-s-o:before {
    content: "\e626";
}

.uf-book:before {
    content: "\e62a";
}

.uf-bookmark-o:before {
    content: "\e631";
}

.uf-leaf:before {
    content: "\e62d";
}

.uf-bullseye:before {
    content: "\e632";
}

.uf-calendarpageempty:before {
    content: "\e647";
}

.uf-gridcaretdown:before {
    content: "\e637";
}

.uf-triangle-up:before {
    content: "\e638";
}

.uf-triangle-down:before {
    content: "\e639";
}

.uf-cloud-down:before {
    content: "\e64a";
}

.uf-cloud-up:before {
    content: "\e64b";
}

.uf-bubble:before {
    content: "\e652";
}

.uf-bubble-o:before {
    content: "\e653";
}

.uf-copy:before {
    content: "\e65a";
}

.uf-correct-2:before {
    content: "\e658";
}

.uf-2arrow-left:before {
    content: "\e664";
}

.uf-arrow-down-2:before {
    content: "\e667";
}

.uf-download:before {
    content: "\e669";
}

.uf-earth:before {
    content: "\e66d";
}

.uf-mail-o:before {
    content: "\e66f";
}

.uf-mail:before {
    content: "\e670";
}

.uf-exc:before {
    content: "\e674";
}

.uf-externallink:before {
    content: "\e676";
}

.uf-video:before {
    content: "\e67b";
}

.uf-films:before {
    content: "\e680";
}

.uf-folder:before {
    content: "\e689";
}

.uf-folder-o:before {
    content: "\e68a";
}

.uf-fontselectioneditor:before {
    content: "\e68d";
}

.uf-4square:before {
    content: "\e68e";
}

.uf-gift:before {
    content: "\e693";
}

.uf-github-c:before {
    content: "\e695";
}

.uf-github-s:before {
    content: "\e696";
}

.uf-heart-o:before {
    content: "\e6a3";
}

.uf-heart:before {
    content: "\e6a4";
}

.uf-home:before {
    content: "\e6a2";
}

.uf-i-c-2:before {
    content: "\e6aa";
}

.uf-i:before {
    content: "\e6ab";
}

.uf-triangle-left:before {
    content: "\e6b0";
}

.uf-symlist:before {
    content: "\e6be";
}

.uf-arrow-left-2:before {
    content: "\e6bf";
}

.uf-arrow-right-2:before {
    content: "\e6c0";
}

.uf-arrow-up-2:before {
    content: "\e6c1";
}

.uf-reduce-c:before {
    content: "\e6cb";
}

.uf-reduce-s:before {
    content: "\e6cc";
}

.uf-minus:before {
    content: "\e6ce";
}

.uf-mobile:before {
    content: "\e6e0";
}

.uf-bell-o:before {
    content: "\e6d4";
}

.uf-9square:before {
    content: "\e6d5";
}

.uf-numlist:before {
    content: "\e6dd";
}

.uf-folderopen-o:before {
    content: "\e6d7";
}

.uf-treefolderopen:before {
    content: "\e6d8";
}

.uf-mac:before {
    content: "\e6ed";
}

.uf-camera:before {
    content: "\e6e4";
}

.uf-picture:before {
    content: "\e6e5";
}

.uf-play:before {
    content: "\e6eb";
}

.uf-play-o:before {
    content: "\e6ec";
}

.uf-qm-c:before {
    content: "\e6f4";
}

.uf-qm:before {
    content: "\e6f5";
}

.uf-navmenu-light:before {
    content: "\e6fd";
}

.uf-settings:before {
    content: "\e70c";
}

.uf-cart:before {
    content: "\e711";
}

.uf-histogram:before {
    content: "\e714";
}

.uf-finetune:before {
    content: "\e71a";
}

.uf-sortup:before {
    content: "\e71b";
}

.uf-sortdown:before {
    content: "\e71c";
}

.uf-sort19:before {
    content: "\e71d";
}

.uf-sort91:before {
    content: "\e71f";
}

.uf-za:before {
    content: "\e721";
}

.uf-star-o:before {
    content: "\e726";
}

.uf-star-2:before {
    content: "\e727";
}

.uf-star:before {
    content: "\e728";
}

.uf-luggage:before {
    content: "\e72d";
}

.uf-table:before {
    content: "\e730";
}

.uf-tel:before {
    content: "\e734";
}

.uf-tel-s:before {
    content: "\e735";
}

.uf-terminal:before {
    content: "\e736";
}

.uf-file:before {
    content: "\e738";
}

.uf-file-o:before {
    content: "\e739";
}

.uf-3dot-h:before {
    content: "\e73c";
}

.uf-time-c-o:before {
    content: "\e742";
}

.uf-upload:before {
    content: "\e750";
}

.uf-3dot-v:before {
    content: "\e753";
}

.uf-rmb:before {
    content: "\e757";
}

.uf-arrow-c-o-up:before {
    content: "\e6a9";
}

.uf-reject-2:before {
    content: "\e6ad";
}

.uf-barcode:before {
    content: "\e7fc";
}

.uf-zoom-out:before {
    content: "\e686";
}

.uf-exc-t-o:before {
    content: "\e60a";
}

.uf-pass:before {
    content: "\e6b1";
}

.uf-flow:before {
    content: "\e6b2";
}

.uf-add-c:before {
    content: "\e61a";
}

.uf-arrow-c-o-right-2:before {
    content: "\e6b3";
}

.uf-shelf-on:before {
    content: "\e6b4";
}

.uf-shelf-off:before {
    content: "\e6b5";
}

.uf-file-o-2:before {
    content: "\e60f";
}

.uf-truck-o:before {
    content: "\e6b6";
}

.uf-super:before {
    content: "\e62c";
}

.uf-equipment:before {
    content: "\e630";
}

.uf-arrow-c-o-left-2:before {
    content: "\e6b8";
}

.uf-files-o:before {
    content: "\e6fc";
}

.uf-cloud-o:before {
    content: "\e6ba";
}

.uf-rmb-s-o-2:before {
    content: "\e6bb";
}

.uf-3dot-c-o:before {
    content: "\e6f3";
}

.uf-dafeng:before {
    content: "\e775";
}

.uf-baoxue:before {
    content: "\e776";
}

.uf-bingbao:before {
    content: "\e777";
}

.uf-fengbao:before {
    content: "\e778";
}

.uf-xiaoyu:before {
    content: "\e779";
}

.uf-zhenxue:before {
    content: "\e77a";
}

.uf-zhongyu:before {
    content: "\e77b";
}

.uf-es:before {
    content: "\e747";
}

.uf-flow-o-2:before {
    content: "\e6bc";
}

.uf-activate-2:before {
    content: "\e6c2";
}

.uf-flow-o:before {
    content: "\e6c3";
}

.uf-bulb-2:before {
    content: "\e63a";
}

.uf-mi-c:before {
    content: "\e62f";
}

.uf-top-up:before {
    content: "\e668";
}

.uf-creditcard:before {
    content: "\e8b4";
}

.uf-align-center:before {
    content: "\e8b9";
}

.uf-align-justify:before {
    content: "\e8ba";
}

.uf-align-left:before {
    content: "\e8bb";
}

.uf-align-right:before {
    content: "\e8bc";
}

.uf-ju-c-o:before {
    content: "\e6c4";
}

.uf-truck:before {
    content: "\e990";
}

.uf-setting-c-o:before {
    content: "\e6c5";
}

.uf-users-o:before {
    content: "\e91b";
}

.uf-bag-s-o:before {
    content: "\e6c6";
}

.uf-cai-s:before {
    content: "\e6c7";
}

.uf-listcheck:before {
    content: "\e6c8";
}

.uf-users:before {
    content: "\e794";
}

.uf-i-c:before {
    content: "\e6cd";
}

.uf-building-o:before {
    content: "\e6f6";
}

.uf-rmb-s-o:before {
    content: "\e6d0";
}

.uf-reject:before {
    content: "\e6d1";
}

.uf-9dot:before {
    content: "\e6f7";
}

.uf-loadingstate:before {
    content: "\e600";
}

.uf-gateway:before {
    content: "\e6d3";
}

.uf-ticket-s-o:before {
    content: "\e6d6";
}

.uf-userset:before {
    content: "\e6f8";
}

.uf-puzzle-o:before {
    content: "\e648";
}

.uf-box-o:before {
    content: "\e6d9";
}

.uf-bulb:before {
    content: "\e6da";
}

.uf-exc-t:before {
    content: "\e61b";
}

.uf-rmb-c:before {
    content: "\e6db";
}

.uf-table-s-o:before {
    content: "\e759";
}

.uf-umbrella-o:before {
    content: "\e6dc";
}

.uf-dropbox:before {
    content: "\e69d";
}

.uf-search-light:before {
    content: "\e622";
}

.uf-cart-o:before {
    content: "\e8c4";
}

.uf-kero-col:before {
    content: "\e8c7";
}

.uf-uba-col:before {
    content: "\e8c8";
}

.uf-tinperzc-col:before {
    content: "\e8c9";
}

.uf-tinperzch-col:before {
    content: "\e8ca";
}

.uf-iuap-col:before {
    content: "\e8cb";
}

.uf-iuapdesign-col:before {
    content: "\e8cc";
}

.uf-bee-col:before {
    content: "\e8cd";
}

.uf-neoui-col:before {
    content: "\e8ce";
}

.uf-sparrow-col:before {
    content: "\e8cf";
}

.uf-tinpercn-col:before {
    content: "\e8d0";
}

.uf-tinperen-col:before {
    content: "\e8d1";
}

.uf-arrow-up:before {
    content: "\e8d2";
}

.uf-mailsym:before {
    content: "\e605";
}

.uf-print:before {
    content: "\e60e";
}

.uf-ticket-3:before {
    content: "\e619";
}

.uf-loan:before {
    content: "\e61f";
}

.uf-ticket-2:before {
    content: "\e629";
}

.uf-offwork:before {
    content: "\e62b";
}

.uf-todolist:before {
    content: "\e640";
}

.uf-personin:before {
    content: "\e66a";
}

.uf-ticket:before {
    content: "\e671";
}

.uf-linechart:before {
    content: "\e6fb";
}

.uf-4leaf:before {
    content: "\e6de";
}

.uf-listset:before {
    content: "\e6df";
}

.uf-qi-c-o:before {
    content: "\e621";
}

.uf-exc-c:before {
    content: "\e61d";
}

.uf-code:before {
    content: "\e656";
}

.uf-plug-o:before {
    content: "\e60d";
}

.uf-search-s:before {
    content: "\e991";
}

.uf-treeadd:before {
    content: "\e992";
}

.uf-mi:before {
    content: "\e993";
}

.uf-treeline-copy:before {
    content: "\e994";
}

.uf-listwithdots:before {
    content: "\e9cd";
}

.uf-gridlogo:before {
    content: "\e900";
}

.uf-magnifyingglass:before {
    content: "\e9ce";
}

.uf-anglearrowdown:before {
    content: "\e9cf";
}

.uf-yongyouyunchnen:before {
    content: "\e98d";
}

.uf-yycloud:before {
    content: "\e68f";
}

.uf-funnel-o:before {
    content: "\e63c";
}

.uf-filter:before {
    content: "\e9d0";
}

.uf-filterno:before {
    content: "\e9d1";
}

.uf-clean:before {
    content: "\e9d2";
}

.uf-save:before {
    content: "\e9d3";
}

.uf-export:before {
    content: "\e9d4";
}

.uf-import:before {
    content: "\e9d5";
}

.uf-stop-c:before {
    content: "\e9d6";
}

.uf-rubber:before {
    content: "\e9d7";
}

.uf-bediting:before {
    content: "\e9d8";
}

.uf-maxmize:before {
    content: "\e9d9";
}

.uf-minimize:before {
    content: "\e9da";
}

.uf-globe:before {
    content: "\e9db";
}

.uf-yybs:before {
    content: "\e9dc";
}

.uf-iuap5:before {
    content: "\e9dd";
}

.uf-iuap5c:before {
    content: "\e9ff";
}

.uf-qingkong:before {
    content: "\e666";
}
